import React, { useEffect, useState } from 'react';
import './TimeRangePicker.css'; // Import your CSS file for styling

const TimeRangePicker = ({ onChange,error,value,disabled}) => {
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  console.log(value);
  useEffect(() => {
    const [start, end] = value.split('-').map(time => time.trim().split(' ')[0]);
    setStartTime(start);
    setEndTime(end);
    console.log(start,end);
  }, [value]);
  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
    onChange(formatTime(event.target.value), formatTime(endTime));
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
    onChange(formatTime(startTime), formatTime(event.target.value));
  };

  const formatTime = (time) => {
    const formattedTime = new Date(`2000-01-01T${time}`);
    let hour = formattedTime.getHours();
    const minute = formattedTime.getMinutes();
    const period = hour >= 12 ? 'PM' : 'AM';
  
    // Convert 24-hour format to 12-hour format
    hour = hour % 12 || 12;
  
    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
  };
  
  return (
    <div>
    <div className="time-range-picker">
      <div className="time-input">
        <label htmlFor="startTime">Start Time:</label>
        <input
          type="time"
          id="startTime"
          value={startTime}
          onChange={handleStartTimeChange}
          disabled={disabled}

        />
      </div>
      <div className="time-input">
        <label htmlFor="endTime">End Time:</label>
        <input
          type="time"
          id="endTime"
          value={endTime}
          onChange={handleEndTimeChange}
          disabled={disabled}

        />
      </div>
     

      <div className="time-display">
        {startTime && endTime && (
          <p>
            {formatTime(startTime)} - {formatTime(endTime)}
          </p>
        )}
      </div>
      
    </div>
    <div className="error-message">{error}</div>
    </div>
  );
};

export default TimeRangePicker;
