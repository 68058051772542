import React, { useState, useEffect } from "react";
import "./SlideShow.css";

const YourSlideshow = ({ image }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (image && image.length > 0) {
      const imageUrls = image.map((image) => image);
      setImages(imageUrls);
    }
  }, [image]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     nextSlide();
  //   }, 3000);

  //   return () => clearInterval(intervalId); // Clear interval on component unmount
  // }, [nextSlide]);

  return (
    <div className="slideshow-container">
      <div className="slides">
        {images.length > 0 &&
          images.map((image, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? "active" : ""}`}
            >
              <img src={image} alt={`Slide ${index + 1}`} />
            </div>
          ))}
      </div>
      <div className="controls">
        <button className="prev" onClick={prevSlide}>&#10094;</button>
        <button className="next" onClick={nextSlide}>&#10095;</button>
      </div>
      <div className="dot-container">
        {images.length > 0 &&
          images.map((_, index) => (
            <div
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => setCurrentSlide(index)}
            ></div>
          ))}
      </div>
    </div>
  );
};

export default YourSlideshow;
