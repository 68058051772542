import React from 'react';
import './NoData.css'
import NoDataImg from '../../../Assets/noData.png'

const NoData = () => {

    return (
        <div className='nodata-contianer'>
           <img src={NoDataImg} alt='no data' />
        </div>
    );
};


export default NoData;