import React, { useState } from "react";
import "./Login.css";
import loginImage from "../../Assets/Images/loginImage.png";
import logo from "../../Assets/Images/logo.png";
import userIcon from "../../Assets/Icons/userIcon.png";
import passwordIcon from "../../Assets/Icons/passwordIcon.png";
import CustomButton from "../../components/CustomButton/CustomButton";
import { loginAsync } from '../../redux/actions/authActions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Login = ({loginAsync }) => {
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (e) => {

        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
        console.log(credentials);
    };

    const handleLogin = async(e) => {
        e.preventDefault();
        try {
            await loginAsync(credentials);
            // Redirect to "/dashboard" after successful login
            navigate("/dashboard");
        } catch (error) {
            console.error('Login failed:', error);
        }

    };
    return (
        <div className="main-login-container">
            <div className="login-card-container">
                <div className="login-image-container" >
                    <img className="login-image" src={loginImage} alt="loginImage" onClick={() => window.location.href = '/home'} />
                </div>
                <div className="login-form-container">
                    <div className="login-logo-container">
                        <img className="login-logo" src={logo} alt="logo" />
                    </div>
                    <div className="login-heading"> Welcome to Dashboard</div>
                    <div className="login-input-container">
                        <div className="inputbox-container">
                            <img className="inputbox-icon" src={userIcon} alt="userIcon" />
                            <input className="login-input" placeholder="User Name" type="email"
                                name="email"
                                value={credentials.email}
                                onChange={handleInputChange} />
                        </div>
                        <div className="inputbox-container">
                            <img className="inputbox-icon" src={passwordIcon} alt="passwordIcon" />
                            <input className="login-input" placeholder="Password" type="password"
                                name="password"
                                value={credentials.password}
                                onChange={handleInputChange} />
                        </div>
                        <CustomButton type='login' onClick={handleLogin} label='Login' />
                    </div>

                </div>

            </div>
        </div>
    );
};
export default connect(null, { loginAsync })(Login);
