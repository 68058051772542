// src/redux/reducers/eventReducer.js

import {
  CREATE_EVENT,
  GET_ALL_EVENTS,
  GET_EVENT_BY_ID,
  UPDATE_EVENT,
  DELETE_EVENT,
  GET_FUTURE_EVENTS,
  GET_PAST_EVENTS,
} from '../actions/eventActions';

const initialState = {
  events: [],
  futureEvents: [],
  pastEvents: [],
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_EVENT:
      return {
        ...state,
        events: [...state.events, action.payload],
      };

    case GET_ALL_EVENTS:
      return {
        ...state,
        events: action.payload,
      };

    case GET_EVENT_BY_ID:
      // Assuming action.payload is a single event
      return {
        ...state,
        events: [action.payload],
      };

    case UPDATE_EVENT:
      return {
        ...state,
        events: state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        ),
      };

    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((event) => event._id !== action.payload),
      };

    case GET_FUTURE_EVENTS:
      return {
        ...state,
        futureEvents: action.payload,
      };

    case GET_PAST_EVENTS:
      return {
        ...state,
        pastEvents: action.payload,
      };
      case "CLEAR_EVENT":
        return {
          ...state,
          events: [],
        };


    default:
      return state;
  }
};

export default eventReducer;
