import axios from 'axios';
const API_BASE_URL = 'http://localhost:8000/api'; // Replace with your API URL

// Function to make a login API request
export const loginApi = async (userData) => {
  try {
    const response = await axios.post(`/api/login`, userData);
    console.log(response.data,userData);
    console.log('gfd');
  
    return response.data;
   
  } catch (error) {
    throw error;
  }
};
// Function to make a user registration API request
export const registerApi = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/register`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to make a password reset API request
export const resetPasswordApi = async (email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/reset-password`, { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default  {
  loginApi,
  registerApi,
  resetPasswordApi,
};
