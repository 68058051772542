// src/redux/reducers/galleryReducer.js

import {
    CREATE_GALLERY,
    GET_ALL_GALLERYS,
    DELETE_GALLERY,
    // Add other action types as needed
  } from '../actions/galleryActions';
  
  const initialState = {
    galleries: [],
    // Add other relevant properties as needed
  };
  
  const galleryReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_GALLERY:
        console.log(action.payload);
        return {
          ...state,
          galleries: [...state.galleries, action.payload],
        };
  
      case GET_ALL_GALLERYS:
        return {
          ...state,
          galleries: action.payload,
        };
  
      case DELETE_GALLERY:
        const { galleryId, imageId } = action.payload;
 
        return {
            ...state,
            galleries: state.galleries.filter((gallery) => gallery._id !== action.payload),
          };
      
  
          case "CLEAR_GALLERY":
            return {
              ...state,
              galleries: [],
            };  
      default:
        return state;
    }
  };
  
  export default galleryReducer;
  