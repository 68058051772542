import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';

import authReducer from './reducers/authReducer'; // Import your authReducer and other reducers here
import eventReducer from './reducers/eventReducer';
import contactFormReducer from './reducers/contactFormReducer';
import galleryReducer from './reducers/galleryReducer';

// Combine multiple reducers if you have more than one
const rootReducer = combineReducers({
  auth: authReducer,
  events:eventReducer,// Add more reducers here if needed
  contactus:contactFormReducer,
  gallery:galleryReducer
});

// Create the Redux store with the combined reducers and apply middleware
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
