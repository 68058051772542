import React, { useEffect, useState } from "react";
import "./Objectives.css";
import { getAllGallerys } from '../../../redux/actions/galleryActions';
import { connect } from 'react-redux';
import CustomButton from "../../../components/CustomButton/CustomButton";
import YourSlideshow from "../../../components/SlideShow/SlideShow";
import m1 from "../../../Assets/members/m1.JPG";
import m2 from "../../../Assets/members/m2.JPG";
import m3 from "../../../Assets/members/m3.jpg";
import m4 from "../../../Assets/members/m4.JPG";
import m5 from "../../../Assets/members/m5.JPG";
import m6 from "../../../Assets/members/m6.JPG";
import m7 from "../../../Assets/members/m7.jpg";
import m8 from "../../../Assets/members/m8.JPG";
import m9 from "../../../Assets/members/m9.JPG";

const image = [m1, m2, m3, m4, m5, m6, m7, m8, m9];



const Objectives = [
  {
    id: 1,
    description: "To arrange assemblage facilities as and when required for the members of the Association for fellowship, educational, cultural and recreational purposes of the community."
  },
  {
    id: 2,
    description: "To provide necessary information for health and socio-economic welfare of the seniors so as to provide amongst them a standard of living comparable to that of the general Australian community."
  },
  {
    id: 3,
    description: "To facilitate better and ongoing communication, co-operation and co-ordination where desirable between our members and government bodies at all levels, including relevant non-government and community organizations within Australia."
  },
  {
    id: 4,
    description: "To identify the individual and social needs of the elderly migrant members and their families, and provide appropriate assistance for them to settle in smoothly and integrate into the Australian multi-cultural milieu with relative ease and without undue stress."
  },
  {
    id: 5,
    description: "To Provide an organizational structure for ATSA with provision for the establishment of branches, if necessary, and be capable of providing effective and efficient services to every member of ATSA to be accessed equitably by all members of ATSA through their branches wherever they may reside within the state of New South Wales and in other states of Australia as appropriate."
  },
  {
    id: 6,
    description: "To assist members in need of housing through advisory and support services to find suitable accommodation through co-operative housing, retirement homes, nursing homes etc. According to needs and capabilities."
  },
  {
    id: 7,
    description: "To provide mutual assistance and benevolent services to members according to needs and as appropriate in such areas as hospital visits, terminally ill members, refugee assistance and specifically the provision of funeral expenses through a contributory benevolent financial scheme devised professionally by a competent actuary."
  },
  {
    id: 8,
    description: "To affiliate, if desirable, with other community organizations, pensions organizations, and/or benevolent associations with similar objectives, both within NSW and/or from interstate if such affiliation is considered mutually beneficial and expected to promote the future progress of the affiliating bodies concerned."
  },
  {
    id: 9,
    description: "To plan, promote and carry out identified social, cultural, educational, recreational and benevolent services for the benefit of the members and of the community in general."
  },
  {
    id: 10,
    description: "To undertake such other activities that are concerned beneficial to the community and would also enhance the furtherance of the aims and objectives of the Association."
  },
  {
    id: 11,
    description: "To arrange assemblage facilities as and when required for the members of the Association for fellowship, educational, cultural and recreational purposes of the community."
  },
  {
    id: 12,
    description: "To provide necessary information for health and socio-economic welfare of the seniors so as to provide amongst them a standard of living comparable to that of the general Australian community."
  }
];


const Objective = ({gallery,getAllGallerys}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getAllGallerys();
    };
    fetchData();
}, [getAllGallerys]);
// useEffect(() => {
//   // Create an array of image URLs from the gallery
//   if(gallery&&gallery.length>0){
//   const imageUrls = gallery&&gallery.length>0&&gallery.map((image) => image.url);
//   setImages(imageUrls);
//   console.log(imageUrls);
// }
// }, [gallery]);
console.log(images,image);
  return (

    <div className="ObjectiveMainContainer">
      <div className="ObjectiveImageContainer">
        
          {console.log(images)}
          {images&&images.length > 0 ? <YourSlideshow image={images} />:<YourSlideshow image={image} />}

      </div>
      <div className="ObjectiveContainer">
        <p className="ObjectiveHeading">Objective</p>
        <ul>
          <p className="objectiveList">
            {Objectives.slice(0, 3).map((objective) => (
              <li key={objective.id}>{objective.description}</li>
            ))}


          </p>
        </ul>
        <div className="objective-button">  <CustomButton type="submit" onClick={() => window.location.href = '/about/#objective'} label="Learn more" /></div>
        <div className="objective-button-text" onClick={() => window.location.href='/about/#objective'}>see more >></div>

      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  gallery: state.gallery.galleries,
});

export default connect(mapStateToProps, { getAllGallerys })(Objective);
