import axios from 'axios';

// Function to make a login API request
export const contactUsApi = async (userData) => {
  try {
    const response = await axios.post(`/api/send-email`, userData);
    console.log(response.data,userData);
    console.log('gfd');
  
    return response.data;
   
  } catch (error) {
    throw error;
  }
};