// Header.js
import React, { useState } from 'react';
import './header.css';
import { useNavigate } from 'react-router-dom';
const Header = ({ onOpenNav }) => {
  // Add state for the profile menu
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const navigate = useNavigate();

  // Dummy user data
  const userData = {
    userName: '',
    userEmail: '',
  };

  const handleProfileClick = () => {
    // Toggle the profile menu on avatar click
    setProfileMenuOpen(!isProfileMenuOpen);
  };

  const handleLogout = () => {
    // Implement your logout logic here
    // For example, redirect to the logout page or dispatch a logout action
    localStorage.removeItem('token');
    navigate("/login");

    console.log('Logout clicked');
  };

  return (
    <div className="header">
      <div className="header-left"  onClick={onOpenNav}>
      <i class="fa-solid fa-bars"></i>
        {/* Add your logo component here */}
        {/* <img src="https://res.cloudinary.com/do7vats9h/image/upload/v1703313376/jey1-atsa_enblem-removebg_blgs0g.png" alt="Logo" className="header-logo" /> */}
      </div>
      
      <div className="header-right">
        {/* Add your notification component here */}
        {/* <div className="notification">Notification</div> */}
        
        {/* Add profile avatar and menu */}
        <div className="profile" onClick={handleProfileClick}>
          {/* Add your avatar image or component here */}
          <img src="https://st2.depositphotos.com/1104517/11967/v/950/depositphotos_119675554-stock-illustration-male-avatar-profile-picture-vector.jpg" alt="Avatar" className="avatar" />
        </div>
        
        {/* Display the profile menu when it's open */}
        {isProfileMenuOpen && (
          <div className="profile-menu">
            <div>{userData.userName}</div>
            <div>{userData.userEmail}</div>
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
