import React from 'react';
import './TitleCard.css'


const TitleCard = ({ title, buttonIcon, buttonTitle,onClick ,mode}) => {

    return (
        <div className='title-card-contianer'>
            <div className='title-card-title'>
                {title && title}
            </div>
            <div className='title-card-button-container'>
                <button className='title-card-button' onClick={() => onClick(mode)}>
                    <div className='title-card-button-icon'>      <i class={buttonIcon&&buttonIcon}></i></div>
                    <div className='title-card-button-title'>{buttonTitle&&buttonTitle}</div>


                </button>
            </div>
        </div>
    );
};


export default TitleCard;