
import React from 'react';
import './CustomInput.css';

const CustomTextAreaTopLabel = ({ label, name, value, onChange,onKeyUp,onFocus,placeholder, error,type,disabled }) => {
  return (
    <div className="custom-input-container-top">
      <div>
        <label className="custom-label">{label}</label>
      </div>
      <div className="custom-input-error-container">
        <div>
          <textarea
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
            className="custom-input-with-label"
            placeholder={placeholder}
            disabled={disabled}
            rows="4"
          />
        </div>
        <div className="error-message">{error}</div>
      </div>
    </div>
  );
};

export default CustomTextAreaTopLabel;
