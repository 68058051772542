// src/redux/actions/authActions.js

import { loginApi } from '../../utils/authApi'

// Action Types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'

// Action Creators
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});
export const registerSuccess = (user_id) => ({
  type: REGISTER_SUCCESS,
  payload: user_id,

});

export const login = (user) => ({
  type: 'LOGIN',
  payload: user,
});

export const logout = () => ({
  type: 'LOGOUT',
});

export const setToken = (token) => ({
  type: 'SET_TOKEN',
  payload: token,
});

export const loginAsync = (credentials) => async (dispatch) => {

  try {
    const { user, token } = await loginApi(credentials);
    console.log( await loginApi(credentials));

  
console.log(credentials);
    dispatch(login(user));
    dispatch(setToken(token));

    // You might want to save the token in localStorage or sessionStorage for persistence
    localStorage.setItem('token', token);
  } catch (error) {
    // Handle login error
    console.error('Login failed:', error);
  }
};