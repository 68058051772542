import React from 'react';
import './CustomInput.css';

const CustomInputTopLabel = ({
  label,
  name,
  value,
  onChange,
  onKeyUp,
  onFocus,
  placeholder,
  error,
  type,
  disabled,
  minDate,
  maxDate,
}) => {
  return (
    <div className="custom-input-container-top">
      <div>
        <div className="custom-label">{label}</div>
      </div>
      <div className="custom-input-error-container">
        <div>
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
            className="custom-input-with-label"
            placeholder={placeholder}
            disabled={disabled}
            id={value}
            // Set min and max attributes for the date input
            {...(type === 'date' && { min: minDate, max: maxDate })}
          />
        </div>
        <div className="error-message">{error}</div>
      </div>
    </div>
  );
};

export default CustomInputTopLabel;
