import React from 'react'
import "./Footer.css";
import logo from "../../../Assets/Images/logo.png";
import socialMediaIcon from "../../../Assets/Icons/socialMediaIcon.png";

const Footer = () => {
  return (
    <div className="contactFooterContainer">
      <div className="subContactFooterContainer">
        <div className="logoContainer">
          <img className="logo" src={logo} alt="logo" />
          <div className="welcomeDes">
            {" "}
            Our association takes pride in creating a supportive and engaging
            environment where seniors can come together, share experiences, and
            participate in meaningful activities.
          </div>
        </div>
     
        <div className="linksContainer">
          <div className="footerHeading">Useful Links</div>
          <ul className="desText">

            <li><a href='/home'>Home</a></li>
            <li><a href='/about'>About</a></li>
            <li><a href='/event'>Event</a></li>
            <li><a href='/home/#service'>Services</a></li>
            <li><a href='/home/#contact'>Contact</a></li>




          </ul>
          </div>
        
        <div className="contactsContainer">
          <div className="footerHeading">Contacts</div>
          
          <div className="desText">
            <p className="desText"> Address :1 DREW Street.
              WESTMEAD, NSW 2145</p>
            <p className="desText"> Email :eajey@hotmail.com</p>
            <p className="desText"> Number :+61048738530</p>
          </div>
        </div>

        <div className="socialMediaContainer">
          <div className="footerHeading">Follow us</div>
          <img
            className="socialMediaIcon"
            src={socialMediaIcon}
            alt="socialMediaIcon"
          />
        </div>

      </div>
      <div className="copyrightContainer">
        <p className="desText">All Rights Reserved © Australian Senior Association <br/> Developed by <br/><a href='https://oreos2.com/'>Oreo Software Solutions</a></p>
      </div>
    </div>
  )
}
export default Footer