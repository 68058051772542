import React, { useState, useEffect } from 'react';
import './GalleryManagement.css'
import TitleCard from '../../../components/Dashboard/TitleCard/TitleCard';
import CustomTable from '../../../components/Dashboard/CustomTable/CustomTable';
import IconButton from '../../../components/Dashboard/IconButton/IconButton';
import GalleryPopUp from './GalleryPopUp/GalleryPopUp';
import axios from 'axios';
import { createGallerySuccess, updateGallerySuccess,deleteGallerySuccess, clearGallery } from './../../../redux/actions/galleryActions';
import { useDispatch, useSelector } from 'react-redux';
import DeletePopUp from '../../../components/Dashboard/DeletePopUp/DeletePopUp';
import NoData from '../../../components/Dashboard/NoData/NoData';
import m1 from "../../../Assets/members/m1.JPG";
import m2 from "../../../Assets/members/m2.JPG";
import m3 from "../../../Assets/members/m3.jpg";
import m4 from "../../../Assets/members/m4.JPG";
import m5 from "../../../Assets/members/m5.JPG";
import m6 from "../../../Assets/members/m6.JPG";
import m7 from "../../../Assets/members/m7.jpg";
import m8 from "../../../Assets/members/m8.JPG";
import m9 from "../../../Assets/members/m9.JPG";

const images = [m1, m2, m3, m4, m5, m6, m7, m8, m9];
const GalleryManagmentContainer = () => {
  const [isUserPopUpOpen, setIsUserPopUpOpen] = useState(false);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [image, setImage] = useState([]);
  const [mode, setMode] = useState('')
  const [userId, setUserId] = useState('')
  const [galleryId, setGalleryId] = useState('')

  // const [deleteUser, setdeleteUser] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 12;
  const openImagePreview = (image) => {
    setSelectedImage(image);
  };

  const closeImagePreview = () => {
    setSelectedImage(null);
  };
  const dispatch = useDispatch();
  let usersData = useSelector((state) => state.gallery);
  console.log(usersData);
  let userArray = usersData.galleries
  console.log(userArray);
  const closeUserPopUp = () => {
    setIsUserPopUpOpen(false);
  }; const openUserPopUp = () => {
    setIsUserPopUpOpen(true);
  };

  const closeDeletePopUp = () => {
    setIsDeletePopUpOpen(false);
  }; const openDeletePopUp = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleButtonClick = (mode, user_id) => {
    console.log('Button clicked with mode:', mode, user_id);
    setMode(mode)
    console.log(user_id);
    user_id && setUserId(user_id);
    // Do other things based on the mode value
    if(user_id){
    openUserPopUp()}
  }; 
   const handleButtonClickCreate = (mode) => {
    console.log('Button clicked with mode:', mode);
    setMode(mode)
  
    // Do other things based on the mode value
   
    openUserPopUp()
  };
  const handleDeleteClick = () => {
    console.log(userId);
    if (userId) {
      const apiUrl = `/api/gallery/${galleryId}/image/${userId}`;

      axios.delete(apiUrl)
        .then((response) => {
          // Handle the success response here
          console.log('User deleted successfully:');
          dispatch(deleteGallerySuccess(userId));

          closeDeletePopUp()
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error('Error submitting data:', error);
        });
    }
  }
  const handleDeleteButtonClick = (mode, user_id) => {
    console.log('Button clicked with mode:', mode, user_id);
    setMode(mode)
    console.log(user_id);
    user_id && setUserId(user_id);
    // Do other things based on the mode value
    if(user_id){
      openDeletePopUp()
    }
  };
  useEffect(() => {

    console.log('lessorArray');
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/galleries');
        const userData = response.data[0].images;
        setGalleryId(response.data[0]._id)
        setImage(userData)
        dispatch(clearGallery());
        userData.forEach((lessor) => {
            console.log(lessor);
          dispatch(createGallerySuccess(lessor));
        });
        // console.log(lessorsData[0].totalPages, lessorsData[0].totalUsers);
  
        // setTotalPage(lessorsData[0].totalPages)
        // setTotalRows(lessorsData[0].totalUsers)
      } catch (error) {
        // setError('Error fetching user data.');
      }
    };
    fetchData();



  }, []);

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = userArray.slice(indexOfFirstImage, indexOfLastImage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div>
      <GalleryPopUp isOpenProp={isUserPopUpOpen} closePopup={closeUserPopUp} mode={mode} userId={userId} />
      <DeletePopUp isOpenProp={isDeletePopUpOpen} closePopup={closeDeletePopUp} onclick={handleDeleteClick} title={'Delete Image'} description={'Are you sure want to delete image'} deleteButtonTitle={'Delete'} />

      <TitleCard
        title='Gallery   Managment'
        buttonIcon={'fa fa-regular fa-image'}
        buttonTitle={'Add Image'}
        onClick={handleButtonClickCreate}
        mode="create"

      />

<div className="gallery-management-MainContainer">
     
      <div className="gallery-management-List">
      {console.log(image)}

        {currentImages.map((image, index) => (<div className='gallery-img-container'>
            {console.log(image)}
          <img
            key={index}
            alt={`image-${index}`}
            src={image.url}
            onClick={() => openImagePreview(image.url)}
          />
          <div className='gallery-img-delete-container'><i class="fa-solid fa-trash" onClick={() => handleDeleteButtonClick('delete',image._id)}  ></i></div>

          </div>
        ))}
      </div>

      {selectedImage && (
        <div className="image-preview-modal" onClick={closeImagePreview}>
          <img src={selectedImage} alt="preview" loading="lazy" />
        </div>
      )}
    </div>
    <br/>
    <div className="pagination">
  <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
    Previous
  </button>
  {Array.from({ length: Math.ceil(userArray.length / imagesPerPage) }).map((_, index) => (
    <button key={index} onClick={() => paginate(index + 1)} className={currentPage === index + 1 ? 'active' : ''}>
      {index + 1}
    </button>
  ))}
  <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(userArray.length / imagesPerPage)}>
    Next
  </button>
</div>
    </div>
  );
};


export default GalleryManagmentContainer;