import React from "react";
import "./Services.css";
import { AiFillCar } from "react-icons/ai";
import { FaRegHandshake } from "react-icons/fa";
import file from '../../../Assets/files/ATSA Application Form.pdf'
import file1 from '../../../Assets/files/BF application Form.pdf'
import file2 from '../../../Assets/files/Nomination form.pdf'
const Services = () => {
  return (
    <div className="mainServiceContainer" id='service'>
      <div className="desContainer">
        <div className="desHeading">
          We Provide Awesome 
          <div className="desHeading" style={{ color: "#195776" }}>
             Services
          </div>
        </div>
        <div className="servicesDes">we provide services for each meetings</div> 
        <div className="service-pdf-container">
        <a href={file} download={file}><i class="fa-solid fa-file-pdf"></i>Download ATSA Application Form </a>
        <a href={file1} download={file1}><i class="fa-solid fa-file-pdf"></i>Download BF application Form </a>
        <a href={file2} download={file2}><i class="fa-solid fa-file-pdf"></i>Download Nomination form </a>

       
    </div>
      </div>
      <div className="iconContainer">
        <div className="iconBackgroundContainer"></div>
        <div className="mainIconContainer">
          <div className="subIconContainer">
           <div> <AiFillCar className="servicesIcon" /></div>
           <div className="iconText">Hours of meeting</div>
           <div> 
            <p className="iconTextdes"> Every Wednesday<span> 09.00am - 2.00pm</span></p>
            <p className="iconTextdes"> Every last Wednesday in a month - Members' day</p>
            <p className="iconTextdes"> Meeting will be closed for 2-3 weeks during the Christmas time. <br/>(Please contact Management) </p>
            
           </div>

          </div>
          <div className="subIconContainer">
           <div><FaRegHandshake className="servicesIcon" /></div> 
            <div className="iconText">Car Parking</div>
            <div> 
            <p className="iconTextdes"> Limited car park available on the Reg Byone Hall property.</p>
            <p className="iconTextdes"> Entry from Darcy Road and  Fyall Avenue.</p>
            <p className="iconTextdes"> Road side parking available</p>
            </div>

          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Services;
