// src/redux/actions/galleryActions.js

import {
    createGalleryApi,
    getAllGallerysApi,
    deleteGalleryApi,
  
  } from '../../utils/galleryApi';
  
  // Action Types
  export const CREATE_GALLERY = 'CREATE_GALLERY';
  export const GET_ALL_GALLERYS = 'GET_ALL_GALLERYS';
  export const GET_GALLERY_BY_ID = 'GET_GALLERY_BY_ID';
  export const UPDATE_GALLERY = 'UPDATE_GALLERY';
  export const DELETE_GALLERY = 'DELETE_GALLERY';
  export const GET_FUTURE_GALLERYS = 'GET_FUTURE_GALLERYS';
  export const GET_PAST_GALLERYS = 'GET_PAST_GALLERYS';
  
  // Action Creators
  export const createGallerySuccess = (gallery) => ({
    type: CREATE_GALLERY,
    payload: gallery,
  });
  
  export const getAllGallerysSuccess = (galleries) => ({
    type: GET_ALL_GALLERYS,
    payload: galleries,
  });
  

  
  export const deleteGallerySuccess = (imageId) => ({
    type: DELETE_GALLERY,
    payload:imageId,
  });
  
  
  
  export const clearGallery = () => {
    return {
        type: "CLEAR_GALLERY"
    };
};
  // Thunk Actions
  export const createGallery = (galleryData) => async (dispatch) => {
    try {
      const gallery = await createGalleryApi(galleryData);
      dispatch(createGallerySuccess(gallery));
    } catch (error) {
      console.error('Error creating gallery:', error);
      // Handle error as needed
    }
  };
  
  export const getAllGallerys = () => async (dispatch) => {
    try {
      const galleries = await getAllGallerysApi();
      console.log(galleries);
      dispatch(getAllGallerysSuccess(galleries));
    } catch (error) {
      console.error('Error fetching galleries:', error);
      // Handle error as needed
    }
  };
  

  
  export const deleteGallery = (galleryId,imageId) => async (dispatch) => {
    try {
      await deleteGalleryApi(galleryId,imageId);
      dispatch(deleteGallerySuccess(galleryId,imageId));
    } catch (error) {
      console.error('Error deleting gallery:', error);
      // Handle error as needed
    }
  };
  

  