import React, { useEffect } from "react";
import "./EventUpdate.css";
import { connect } from 'react-redux';
import { getFutureEvents } from '../../../redux/actions/eventActions';
import NoData from "../../../components/Dashboard/NoData/NoData";
const EventUpdate = ({ futureEvents, getFutureEvents }) => {

    useEffect(() => {
        getFutureEvents();
    }, [getFutureEvents]);
    function getFormattedDate(dateString) {
        const date = new Date(dateString);

        // Format month to get Jan, Feb, etc.
        const monthOptions = { month: 'long', timeZone: 'UTC' };
        const formattedMonth = date.toLocaleDateString('en-US', monthOptions);

        // Format day as a two-digit string
        const formattedDay = date.getUTCDate().toLocaleString('en-US', { minimumIntegerDigits: 2 });

        return { formattedMonth, formattedDay };
    }
    return (
        <div className="mainEventUpdateContainer" id="event">
            {futureEvents && futureEvents.length > 0 ?
                <> <div className="Event-heading">Events</div>

                    <div className="eventUpdateContainer">
                        {
                            futureEvents.map((event, index) => (
                                <div className="subEventUpdateContainer" key={index}>
                                    <div className="dateMonthContainer">
                                        <div className="dateContainer">
                                            {getFormattedDate(event.date).formattedDay}
                                        </div>
                                        <div className="monthContainer">
                                            {getFormattedDate(event.date).formattedMonth}
                                        </div>
                                    </div>
                                    <div className="updateContainer">
                                        <div className="updateEventHeadingContainer">
                                            {event.title}
                                        </div>
                                        <div className="updateEventDateContainer">
                                            <div>{new Date(event.date).toLocaleDateString('en-US', {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric'
                                            })}    </div>        <div> {event.time_range}      </div>                </div>
                                        <div className="updateEventDesContainer">
                                            {event.subtitle}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div></> : <><div className="Event-heading">No Events Availalable now..</div>
                    <NoData />
                </>}
        </div>
    );
};

const mapStateToProps = (state) => ({
    futureEvents: state.events.futureEvents,
});

export default connect(mapStateToProps, { getFutureEvents })(EventUpdate);
