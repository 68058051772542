// src/utils/eventApi.js

import axios from 'axios';



export const createGalleryApi = async (eventData) => {
  try {
    const response = await axios.post('/api/galleries', eventData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllGallerysApi = async () => {
  try {
    const response = await axios.get('/api/galleries');
    return response.data[0].images;
  } catch (error) {
    throw error;
  }
};


export const deleteGalleryApi = async (galleryId,imageId) => {
  try {
    await axios.delete(`/api/gallery/${galleryId}/image/${imageId}`);
  } catch (error) {
    throw error;
  }
};


