import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import axios from 'axios'
import './DeletePopUp.css'
function DeletePopUp({ isOpenProp, closePopup,onclick, title, description, userId, deleteButtonTitle }) {


  const handleClose = () => {
    closePopup()

  }

  return (
    <CustomPopup isOpen={isOpenProp} onClose={handleClose} style={{height:'230px',minHeight:200}}>
      <div className="delete-popup">
        <div className="delete-popup-head">{title}
          {/* <hr/> */}
        </div>

        <div className="delete-popup-container">

          {description}
        </div>

        <div className="delete-popup-button-container">
          <div>
            <CustomButton label={deleteButtonTitle} onClick={onclick} type="submit" styles={{ minWidth: '120px', marginTop: 10, background: '#D03636' }} />
          </div>
          <div>
            <CustomButton label={"Cancel"} onClick={handleClose} type="cancel-btn" styles={{ minWidth: '120px', marginTop: 10 }} />
          </div>
        </div>
      </div>
    </CustomPopup>
  );

}

export default DeletePopUp;
