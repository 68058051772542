// Nav.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './nav.css'
import navConfig from './config'
const Nav = ({ openNav, onCloseNav }) => {
  const location = useLocation();

  const navStyle = {
    width: openNav ? '300px' : '0px',
  };

  return (
    <nav className="nav" style={navStyle}>
      {/* <button onClick={onCloseNav}>Close Nav</button> */}
      <ul>
        {navConfig.map((item, index) => (
          <li key={index} className={location.pathname === item.path ? 'nav-active' : 'nav-inactive'||openNav?'nav-short':''}>
            <Link to={item.path}><i class={item.icon}></i>{openNav?item.title:''}</Link>
          </li>
        ))}
      </ul>

    </nav>
  );
};

export default Nav;
