// src/actions/contactFormActions.js
import { contactUsApi } from '../../utils/contactUsApi'

export const updateFormField = (field, value) => ({
    type: 'UPDATE_FORM_FIELD',
    payload: {
      field,
      value,
    },
  });
  
  export const resetForm = () => ({
    type: 'RESET_FORM',
  });
  export const contactUsAsync = (contactData) => async (dispatch) => {
    try {
      const responseData = await contactUsApi(contactData);
  
      // Assuming your API response contains user and token data
  
      console.log(responseData, contactData);
  
      dispatch(updateFormField(responseData));
      // Assuming you have another action to set the token
      // dispatch(setToken(token));
  
      // You might want to save the token in localStorage or sessionStorage for persistence
    } catch (error) {
      // Handle contact us error
      console.error('Contact us failed:', error);
    }
  };