import React, { useEffect } from "react";
import "./EventsPage.css";
import eventPageImage from "../../Assets/Images/eventPageImage.png";
import EventUpdate from "./EventUpdate/EventUpdate";

const EventsPage = () => {
    useEffect(() => {
        // Scroll to the "Contact" section when the component mounts
        const contactSection = document.getElementById('objective');
        if (contactSection&&window.location.hash=='#objective') {
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }
        const serviceSection = document.getElementById('event');
        if (serviceSection&&window.location.hash=='#event') {
          serviceSection.scrollIntoView({ behavior: 'smooth' });
        }
        console.log(window.location.hash);
      }, []);
    return (
        <div>
            <div className="mainEventContainer" >
                <div className="eventPageHeadingContainer">
                    <div className="eventPageHeading">
                        Upcoming Events!
                    </div>
                    <p className="eventPageDes">
                    Stay in the loop and mark your calendars with our exciting upcoming events! Whether it's community gatherings, workshops, or special occasions, our events calendar is your go-to source for what's happening.
                    </p>
                </div>
                <div className="eventPageImageContainer">
                <img className="eventPageImage" src={eventPageImage} alt="eventPageImage" />
                </div>
            </div>
        <EventUpdate/>
        </div>


    );
};

export default EventsPage;
