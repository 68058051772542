// Navbar.js

import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from "../../../Assets/Images/logo.png";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(window.location.pathname!=='/'?window.location.pathname:'/home');
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  useEffect(() => {
    setActiveLink(window.location.pathname!=='/'?window.location.pathname.replace(/\/$/, ''):'/home')
    console.log(activeLink);
  }, [])
  

  const handleContactClick = () => {
    const contactElement = document.getElementById('contact');

    if (contactElement) {
      contactElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.location.href = '/home#contact';
    }
  };

  return (
    <div className="navbar">
      <div className="logo">
        <div style={{display:'flex'}}>
        <img className="logo" src={logo} alt="logo" onClick={() => window.location.href = '/home'} /> 
        </div>
      </div>
      <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
        <i className="fa-solid fa-bars"></i>
      </div>
      <ul className={`nav-menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <li className={activeLink === '/home' ? 'active' : ''} onClick={() => setActiveLink('/home')}>
          <a href="/home">Home</a>
        </li>
        <li className={activeLink === '/about' ? 'active' : ''} onClick={() => setActiveLink('/about')}>
          <a href="/about">About</a>
        </li>
        <li className={activeLink === '/event' ? 'active' : ''} onClick={() => setActiveLink('/event')}>
          <a href="/event">Events</a>
        </li>
        <li className={activeLink === '#service' ? 'active' : ''} onClick={() => setActiveLink('/home#service')}>
          <a href="/home#service">Services</a>
        </li>
        <li className={activeLink === '#contact' ? 'active' : ''} onClick={handleContactClick}>
          <a href="/home#contact">Contact</a>
        </li>
      </ul>
    </div>
  );
}

export default Navbar; 
