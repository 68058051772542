import React, { useEffect } from "react";
import "./Events.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { connect } from 'react-redux';
import { getFutureEvents } from '../../../redux/actions/eventActions';

const events = [{
  time: '10.00 am - 12.00 pm',
  date: 'November 30, 2023',
  title: 'Community Gathering and Updates',
  description: `Join us for a community meeting where we will discuss important
updates, upcoming events, and gather feedback from members.`
},
{
  time: '10.00 am - 12.00 pm',
  date: 'November 30, 2023',
  title: 'Community Gathering and Updates',
  description: `Join us for a community meeting where we will discuss important
updates, upcoming events, and gather feedback from members.`
}
]

const Events = ({ futureEvents, getFutureEvents }) => {
  useEffect(() => {
    getFutureEvents();
}, [getFutureEvents]);
function getFormattedDate(dateString) {
    const date = new Date(dateString);

    // Format month to get Jan, Feb, etc.
    const monthOptions = { month: 'long', timeZone: 'UTC' };
    const formattedMonth = date.toLocaleDateString('en-US', monthOptions);

    // Format day as a two-digit string
    const formattedDay = date.getUTCDate().toLocaleString('en-US', { minimumIntegerDigits: 2 });

    return { formattedMonth, formattedDay };
}
  return (
    <>
    {futureEvents && futureEvents.length > 0 ? 
    <div className="event-container">
      <div className="eventContainer">
        {futureEvents.slice(0, 2).map((event, index) => <div key={index} className="events">
          <div className="eventDesBox">
            <div className="subEventBox">
              <p className="subEventBoxText">
              {getFormattedDate(event.date).formattedDay}

              </p>
              <p className="subEventBoxText">
              {getFormattedDate(event.date).formattedMonth}
              </p>
            </div>
            <div className="subEventDes">
              <p className="subEventDesHeading">
                {event.title}
              </p>
                     <div> {event.time_range}      </div>   
              <p className="subEventDesText">
                {event.subtitle}
              </p>
            </div>
          </div>
        </div>)}

      </div>
      <div className="eventDesContainer">
        <div className="eventDesHeading-container">
          <div className="eventDesHeading">
            Featured upcoming
          </div>
          <div className="event-head"> Events</div>
        </div>
        <div>
          <div className="eventDesText">Explore our featured </div>
          <div className="eventDesText">upcoming events</div></div>
     
     <div className="learnMore-btn">   <CustomButton type="submit" label="Learn more" onClick={() => window.location.href = '/event/#event'} /></div>
     <div className="learnMore-txt" onClick={() => window.location.href = '/event/#event'}> see more >> </div>

      </div>
    </div>:<></>}
    </>
  );
};
const mapStateToProps = (state) => ({
  futureEvents: state.events.futureEvents,
});
export default connect(mapStateToProps, { getFutureEvents })(Events);
