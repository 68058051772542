import React, { useEffect } from "react";
import AboutHeader from "../container/About/AboutHeader/AboutHeader";
import Commitee from "../container/About/Commitee/Commitee";
import Objective from "../container/About/Objectives/Objectives";
import Gallery from "../container/About/Gallery/Gallery";

const About = () => {
  useEffect(() => {
    // Scroll to the "Contact" section when the component mounts
    const contactSection = document.getElementById('objective');
    if (contactSection && window.location.hash == '#objective') {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
    const serviceSection = document.getElementById('community');
    if (serviceSection && window.location.hash == '#community') {
      serviceSection.scrollIntoView({ behavior: 'smooth' });
    }
    console.log(window.location.hash);
  }, []);
  return (

    <div >
      <AboutHeader />
      <Commitee />
      <Objective />
      <Gallery />

    </div>
  );
};

export default About;
