import React,{useEffect} from 'react';
import Home from "./Pages/Home";
import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import EventsPage from './container/Event/EventsPage';
import About from './Pages/About';
import Login from './container/Login/Login';
import { setToken } from './redux/actions/authActions';
import EventManagment from './Pages/Dashboard/EventManagment';
import GalleryManagmentContainer from './container/dashboard/GalleryManagement/GalleryManagement';



export default function Router() {
  // const isAuthenticatedRedux = useSelector(state => state.auth.isAuthenticated);
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, [setToken]);

  const isAuthenticated = localStorage.getItem('token');
console.log(useSelector(state => state.auth));
console.log(isAuthenticated);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (isAuthenticated )? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        { element: <Navigate to="/dashboard/event-managment" />, index: true },
        { path: 'event-managment', element: <EventManagment /> },
        { path: 'gallery-managment', element: <GalleryManagmentContainer /> },

        // { path: 'user', element: <UserPage /> },
        // { path: 'products', element: <ProductsPage /> },
        // { path: 'blog', element: <BlogPage /> },
      ]
    },
    {
      path: '',
      element:  <SimpleLayout />,
      children: [
        { path: '', element: <Navigate to="/home" replace />, index: true },
        // { path: '404', element: <Page404 /> },
        { path: '/home', element: <Home /> },
        { path: '/event', element:<EventsPage/>},
        { path: '/about', element:<About/>},
      
        // { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // { path: '*', element: <Navigate to="/404" replace /> },
    {
      path: '/login',
      element:<Login/>,
    },
  ]);

  return routes;
}