// Toast.js
import React from 'react';
import './toast.css';

const Toast = ({ message, showToast, onClose ,type}) => {
  return (
    <div className={`toast ${showToast ? 'show' : ''} ${type}`}>
      <div className="toast-content">
        <div>{message}</div>
        <button className="close-btn" onClick={onClose}>
          
        </button>
      </div>
    </div>
  );
};

export default Toast;
