import React from 'react';
import './CustomTable.css';

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const CustomTable = ({ tableHead, tableData, cellWidths, onClick, mode, userIds, rowAlignments }) => {
  return (
    <div className='custom-table-container'>
      <table className='custom-table'>
        <thead className='custom-table-head'>
          <tr>
            {tableHead.map((header, index) => (
              <th
                key={index}
                style={{
                  minWidth: cellWidths && cellWidths[index]?.minWidth,
                  maxWidth: cellWidths && cellWidths[index]?.maxWidth,
                  width: cellWidths && cellWidths[index]?.width,
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={`custom-table-tr`}
            
            >
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  onClick={typeof cell !== 'object' ? () => onClick(mode, userIds[rowIndex]) : undefined}
                  style={{
                    minWidth: cellWidths && cellWidths[cellIndex]?.minWidth,
                    maxWidth: cellWidths && cellWidths[cellIndex]?.maxWidth,
                    width: cellWidths && cellWidths[cellIndex]?.width,
                    textAlign:rowAlignments && rowAlignments[cellIndex]

                  }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
