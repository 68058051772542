import React from "react";
import "./AboutHeader.css";
import userGroup from '../../../Assets/Images/userGroup.png'
import sydneyIsometric from '../../../Assets/Images/sydneyIsometric.png'
import smileyFace from '../../../Assets/Images/smileyFace.png'
import file from '../../../Assets/files/ATSA Application Form.pdf'
import file1 from '../../../Assets/files/BF application Form.pdf'
import file2 from '../../../Assets/files/Nomination form.pdf'

const AboutHeader = () => {
    return (
        <div>
            <div className="main-about-container" >
                <div className="about-heading-container">
                    <div className="about-heading">JOIN OUR COMMUNITY</div>
                    <div className="about-heading-des-container">
                        Create authentic relationships with us
                    </div>
                    <div className="about-image-container">
                    <img className="sydney-isometric" src={sydneyIsometric} alt="sydneyIsometric"/>
                    <img className="user-group" src={userGroup} alt="userGroup"/>
                    <img className="smiley-face" src={smileyFace} alt="usmileyFace"/>

                    </div>
                    {/* <div id="pdf-container">
        <iframe src={file} width="100%" height="100%"></iframe>
    </div> */}

    {/* <div className="pdf-container">
        <a href={file} download={file}><i class="fa-solid fa-file-pdf"></i>Download ATSA Application Form </a>
        <a href={file1} download={file1}><i class="fa-solid fa-file-pdf"></i>Download BF application Form </a>
        <a href={file2} download={file2}><i class="fa-solid fa-file-pdf"></i>Download Nomination form </a>


    </div> */}
    
                </div>


            </div>
        </div>


    );
};

export default AboutHeader;
