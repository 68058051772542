// src/store/contactFormReducer.js
const initialState = {
  name: '',
  email: '',
  message: '',
  contact_no:''
};

const contactFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_FORM_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'RESET_FORM':
      return initialState;
    default:
      return state;
  }
};

export default contactFormReducer;
