// src/utils/eventApi.js

import axios from 'axios';



export const createEventApi = async (eventData) => {
  try {
    const response = await axios.post('/events', eventData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllEventsApi = async () => {
  try {
    const response = await axios.get('/events');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEventByIdApi = async (eventId) => {
  try {
    const response = await axios.get(`/events/${eventId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateEventApi = async (eventId, eventData) => {
  try {
    const response = await axios.put(`/events/${eventId}`, eventData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteEventApi = async (eventId) => {
  try {
    await axios.delete(`/events/${eventId}`);
  } catch (error) {
    throw error;
  }
};

export const getFutureEventsApi = async () => {
  try {
    const response = await axios.get('/api/events-future');
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPastEventsApi = async () => {
  try {
    const response = await axios.get('/events-future');
    return response.data;
  } catch (error) {
    throw error;
  }
};
