import React from 'react';
import EventManagmentContainer from '../../container/dashboard/EventManagement/EventManagment';



const EventManagment = () => {

  return (
    <div>
        <EventManagmentContainer/>
        
    </div>
  );
};


export default EventManagment;