import React from 'react';
import './IconButton.css'
const IconButton = ({ buttonIcon ,color ,onClick,mode ,user_id }) => {
  return (
    <button className='icon-button'  onClick={() => onClick(mode,user_id)}>
                       <div className='icon-button-icon' style={{color:color}}>      <i class={buttonIcon&&buttonIcon}></i></div>

   </button>
  );
};

export default IconButton;
