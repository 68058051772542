import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import CustomPopup from "../../../../components/CustomPopup/CustomPopup";

import axios from 'axios'
import { useParams } from 'react-router-dom';
// import { addUser, editUser, clearUser } from '../../../redux/actions/userActions';
import { createGallerySuccess, updateGallerySuccess, clearGallery } from '../../../../redux/actions/galleryActions';

import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import './GalleryPopUp.css'
function GalleryPopUp({ isOpenProp, closePopup, data, mode, userId }) {
  console.log(mode, userId,);
  const dispatch = useDispatch();
  const [photo, setPhoto] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  // const debouncedHandleInputChange = debounce(handleInputChange, 300);
  const [isLoading, setIsLoading] = useState(false);


  console.log(photo!='');
  useEffect(() => {
    if (userId && mode != 'delete') {

      const apiUrl = mode === 'update' || 'view' ? `/api/getActiveUserById/${userId}` : '';
      setIsLoading(true);
      console.log(apiUrl);
      if (apiUrl) {
        axios.get(apiUrl)
          .then((response) => {
            // Handle the success response here
            console.log('Data submitted successfully:', response.data);
            const data = response.data
            setSelectedImage(data.user_image);
           
          })
          .catch((error) => {
            // Handle any errors that occur during the request
            console.error('Error submitting data:', error);
          })
          .finally(() => {
            setIsLoading(false); // Set loading to false after the request is complete
          });
      } else {
        console.error('Invalid apiUrl:', apiUrl);
      }
    }
  }, [userId]);

  const handleClose = () => {
    closePopup()
    setSelectedImage('');
    setPhoto('');

  }




  const resizeBase64Image = (base64, targetWidth, callback) => {
    const img = new Image();
  
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const aspectRatio = this.width / this.height;
      const targetHeight = targetWidth / aspectRatio;
  
      // Set the canvas dimensions
      canvas.width = targetWidth;
      canvas.height = targetHeight;
  
      // Draw the image on the canvas
      const ctx = canvas.getContext('2d');
      ctx.drawImage(this, 0, 0, targetWidth, targetHeight);
  
      // Convert the canvas content to base64
      const resizedBase64 = canvas.toDataURL('image/jpeg'); // You can change the format if needed
  
      // Invoke the callback with the resized base64 image
      callback(resizedBase64);
    };
  
    // Set the source of the image to the base64 string
    img.src = base64;
  };
  
  // Example usage in your handleImageChange function
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
console.log(file);
    if (file) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const base64Image = reader.result;
  
        resizeBase64Image(base64Image, 400, (resizedBase64) => {
          // Now you have the resized base64 image
          console.log('Resized Image', resizedBase64);
          setPhoto(resizedBase64);
        });
      };
  
      reader.readAsDataURL(file);
    }
  };
  






  // Usage example:
  const handleSubmit = () => {

  
    if (photo!='') {
      // Handle form submission logic
      console.log("Form is valid. Submitting...");
const apiUrl = mode === 'create' ? '/api/gallery' : `/api/gallery/${userId}`;
setIsLoading(true);
console.log(photo);
const requestData = {
  image: photo,
  // Add any additional fields if needed
};

if (mode === 'create') {
  axios.post(apiUrl, requestData)
    .then((response) => {
      console.log('Data submitted successfully:', response.data);
      
      handleClose();
      // Dispatch addUser with the actual response data
      var index=response.data.images.length
      console.log(response.data.images.length);
      dispatch(createGallerySuccess(response.data.images[index-1]));
    })
    .catch((error) => {
      console.error('Error submitting data:', error);
    })
    .finally(() => {
      setIsLoading(false);
    });
} else if (mode === 'edit') {
  axios.put(apiUrl, requestData)
    .then((response) => {
      console.log('Data submitted successfully:', response.data);
      handleClose();
      // Dispatch editUser with the actual response data
      // dispatch(clearUser());
      // dispatch(editUser(response.data));
    })
    .catch((error) => {
      console.error('Error submitting data:', error);
    })
    .finally(() => {
      setIsLoading(false);
    });
}


    } else {
      console.log("Form is not valid. Please fill in all required fields.");
    }
  };









  return (
    <CustomPopup isOpen={isOpenProp} onClose={handleClose}>
      {isLoading ? (     // Loading indicator (e.g., a spinner)
        <div className="loading-indicator">
          Loading...
        </div>
      ) : (<div className="user-popup">
        <div className="user-popup-head">{mode === 'view' ? 'Upload Image' : (mode === 'update' ? 'Upload Image' : 'Upload Image')}
          {/* <hr/> */}
        </div>


        <div className="user-popup-container">
          <div className="user-popup-image-container" >
            {selectedImage && (
              <div>
                <p>Selected Image:</p>
                {console.log('selectedImage', typeof (selectedImage))}
                <img
                  src={selectedImage ? typeof (selectedImage) == 'object' ? `${photo}` : `data:image/png;base64,${selectedImage}` : 'https://cdn.vectorstock.com/i/preview-1x/17/61/male-avatar-profile-picture-vector-10211761.jpg'}


                  // src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                  style={{ maxWidth: '100%', maxHeight: '200px' }}
                  className="user-popup-image-preview-container"
                />
                {/* <p className="user-popup-image-path"> {imagePath}</p> */}

              </div>

            )}
            {!selectedImage && (
              <div>
                <p>Select the Image:</p>
                <img
                  src={selectedImage ? `${photo}` : 'https://cdn.vectorstock.com/i/preview-1x/17/61/male-avatar-profile-picture-vector-10211761.jpg'}
                  alt="Selected"
                  style={{ maxWidth: '100%', maxHeight: '200px' }}
                  className="user-popup-image-preview-container"
                />
                {/* <p className="user-popup-image-path"> {imagePath}</p> */}

              </div>

            )}
          {mode !== 'view' ? <label htmlFor="user-popup-image-uploader" className="user-popup-image-uploader-label submit">
              Choose Image
            </label>:''}

            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              id="user-popup-image-uploader"

              className="user-popup-image-uploader"
            />
          </div>
        

        </div>

        <div className="user-popup-button-container">
          <div>
            {mode === 'view' ? '' : <CustomButton label={mode === 'update' ? 'Upload Image ' : 'Upload Image'}disabled={photo==''?true:false} onClick={handleSubmit} type="submit" styles={{ minWidth: '190px', marginTop: 10, opacity: photo=='' ? 0.4 : '' }}
 />}
          </div>
          <div>
            <CustomButton label={mode === 'view' ? "Close" : "Cancel"} onClick={handleClose} type="cancel-btn" styles={{ minWidth: '190px', marginRight: '0px', marginTop: 10 }} />
          </div>
        </div>
      </div>)}
    </CustomPopup>
  );

}

export default GalleryPopUp;
