import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import Header from './header/header';
import Nav from './nav';



export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  return (
    <div className='dashboard-container'>
    <Header onOpenNav={() => setOpen(!open)} />

           <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      <div className={open?'nav-open':''+'dashboard-main' }  >
        <Outlet />
      </div> 
      </div>
  );
}
