import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import CustomPopup from "../../../../components/CustomPopup/CustomPopup";

import axios from 'axios'
import { useParams } from 'react-router-dom';
import { createEventSuccess, updateEventSuccess, clearEvent } from '../../../../redux/actions/eventActions';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import './UserPopUp.css'
import CustomTextAreaTopLabel from "../../../../components/Custom_Input/CustomTextAreaTopLabel";
import CustomDropdownTopLabel from "../../../../components/Custom_Input/CustomDropdownTopLabel";
import CustomInputTopLabel from "../../../../components/Custom_Input/CustomInputTopLabel";
import TimeRangePicker from "../../../../components/TimeRangePicker/TimeRangePicker";
function UserPopUp({ isOpenProp, closePopup, data, mode, userId,eventType }) {
  console.log(mode, userId,);
  const dispatch = useDispatch();
  const [forceUpdate, setForceUpdate] = useState(0);
  const roleOptions = [{ name: 'spo' }, { name: 'po' }, { name: 'do' }, { name: 'commissioner' }, { name: 'cdc' }, { name: 'court clerk' }, { name: 'user' }];
  const params = useParams();
  const [userIds, setUserId] = useState('');
  useEffect(() => {
    setUserId(userId)
    setTitle('');
    setSubTitle('');

    setDate('');
    setTimeRange('');

    setFormErrors('');

  }, [mode])
  console.log(userIds);
  const [photo, setPhoto] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubTitle] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [date, setDate] = useState('');
  const [timeRange, setTimeRange] = useState('');
  const [division, setDivision] = useState('');
  const [role, setRole] = useState('');
  const [formErrors, setFormErrors] = useState({});
  // const debouncedHandleInputChange = debounce(handleInputChange, 300);
  const [isLoading, setIsLoading] = useState(false);


  const handleDropdownChange = (e, name) => {
    // console.log(e, material);
    if (name == 'Role') {
      // setFilterMaterial(getMaterial.filter(item => item.material_id === e))

      setRole(e);
      validateField(name, e);
    }
    // else if (name == 'Quantity Measurement') {
    //   setQuantityMeasurement(e);
    //   validateField(name, e);
    // }
    // else if (name=='Time Span') {
    //   setTimeSpan(e);
    //   validateField(name, e);} 
  };
  useEffect(() => {
    if (userId && mode != 'delete') {
      console.log('userId successfully:', userId);

      const apiUrl = mode === 'update' || 'view' ? `/api/events/${userId}` : '';
      setIsLoading(true);
      console.log(apiUrl);
      if (apiUrl) {
        axios.get(apiUrl)
          .then((response) => {
            // Handle the success response here
            console.log('Data submitted successfully:', response.data);
            const data = response.data
            setTitle(data.title);
            setSubTitle(data.subtitle);
            setTimeRange(data.time_range);
            setDate(data.date.split('T')[0]);
          
          })
          .catch((error) => {
            // Handle any errors that occur during the request
            console.error('Error submitting data:', error);
          })
          .finally(() => {
            setIsLoading(false); // Set loading to false after the request is complete

          });
      } else {
        console.error('Invalid apiUrl:', apiUrl);
      }
    }
  }, [userId,forceUpdate]);

  const handleClose = () => {
    
    setForceUpdate(prev => prev + 1)

    setTitle('');
    setSubTitle('');
    setUserId('')

    setDate('');
    setTimeRange('');

    setFormErrors('');
    closePopup()
  }

 






  const validateField = (fieldName, value) => {
    const errors = { ...formErrors };

    switch (fieldName) {
      case "photo":
        // Add validation logic for 'Photo' field if needed
        // Example: Check if the value is a valid image URL
        if (!value || !isValidImageUrl(value)) {
          errors.photo = "Valid image URL is required";
        } else {
          delete errors.photo;
        }
        break;
      case "title":
        if (!value) {
          errors.title = "Title is required";
        } else {
          delete errors.title;
        }
        break;
      case "subtitle":
        if (!value) {
          errors.subtitle = "Subtitle is required";
        } else {
          delete errors.subtitle;
        }
        break;
      case "email":
        // Example email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value || !emailRegex.test(value)) {
          errors.email = "Valid email address is required";
        } else {
          delete errors.email;
        }
        break;
      case "phoneNo":
        // Example phone number validation using a regular expression
        const phoneRegex = /^\d{10}$/;
        if (!value || !phoneRegex.test(value)) {
          errors.phoneNo = "Valid 10-digit phone number is required";
        } else {
          delete errors.phoneNo;
        }
        break;
      case "date":
        // Example date of birth validation
        // You can replace this with your own logic
        if (!value || !isValidDate(value)) {
          errors.date = "Valid date of birth is required";
        } else {
          delete errors.date;
        }
        break;
      case "timeRange":
        console.log(value);
        if (!value) {
          errors.timeRange = "timeRange is required";
        } else {
          delete errors.timeRange;
        }
        break;
      case "division":
        if (!value) {
          errors.division = "Division is required";
        } else {
          delete errors.division;
        }
        break;
      case "role":
        if (!value) {
          errors.role = "Role is required";
        } else {
          delete errors.role;
        }

        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  // Example helper functions
  const isValidImageUrl = (url) => {
    // You can implement your own image URL validation logic
    return true;
  };

  const isValidDate = (date) => {
    // You can implement your own date validation logic
    return true;
  };
  const validateForm = () => {
    const errors = {};

    // if (!selectedImage) {
    //   errors.selectedImage = "Photo is required";
    // }

    if (!title) {
      errors.title = "Title is required";
    }

    if (!subtitle) {
      errors.subtitle = "Subtitle is required";
    }

    // Add similar checks for other fields
   
    if (!date) {
      errors.date = "Date  is required";
    }

    if (!timeRange) {
      errors.timeRange = "Time Range is required";
    }

   

    // if (!action) {
    //   errors.action = "Action is required";
    // }

    setFormErrors(errors);

    // Return true if there are no errors, indicating a valid form
    return Object.keys(errors).length === 0;
  };

  // Usage example:
  const handleSubmit = () => {
    const isFormValid = validateForm();
    console.log('formData');
  
    console.log('formErrors', formErrors);
    if (isFormValid) {
      // Handle form submission logic
      console.log("Form is valid. Submitting...");
      const apiUrl = mode === 'create' ? '/api/events' : `/api/events/${userId}`;
      setIsLoading(true);
  
      const eventData = {
        time_range: timeRange,
        title: title,
        subtitle: subtitle,
        date: date,
      };
  
      // Add any additional fields if needed
  
      (mode === 'create' ? axios.post(apiUrl, eventData) : axios.put(apiUrl, eventData, {
        headers: {
          'Content-Type': 'application/json',
        },
      }))
        .then((response) => {
          // Handle the success response here
          console.log('Data submitted successfully:', response.data);
          handleClose();
  
          // Dispatch addUser with the actual response data
          // dispatch(clearEvent());
if(eventType=='future'){
          (mode === 'create' ? dispatch(createEventSuccess(response.data)) : dispatch(updateEventSuccess(response.data)))
  }
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error('Error submitting data:', error);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false after the request is complete
        });
  
    } else {
      console.log("Form is not valid. Please fill in all required fields.");
    }
  };
  









  return (
    <CustomPopup isOpen={isOpenProp} onClose={handleClose}>
      {isLoading ? (     // Loading indicator (e.g., a spinner)
        <div className="loading-indicator">
          Loading...
        </div>
      ) : (<div className="user-popup">
        <div className="user-popup-head">{mode === 'view' ? 'View Event' : (mode === 'update' ? 'Update Event' : 'Create Event')}
          {/* <hr/> */}
        </div>


        <div className="user-popup-container">
         
          <div className="user-popup-input-container" >
            <CustomInputTopLabel
              label="Title"
              name="title"
              type="text"
              value={title}
              readOnly={mode === 'view'}
              disabled={mode === 'view'}
              onChange={(e) => {
                setTitle(e.target.value);
                validateField("title", e.target.value);
              }}
              placeholder="Enter the Title"
              error={formErrors.title}
            />
            <CustomInputTopLabel
              label="Subtitle"
              name="subtitle"
              type="text"
              value={subtitle}
              readOnly={mode === 'view'}
              disabled={mode === 'view'}
              onChange={(e) => {
                setSubTitle(e.target.value);
                validateField("subtitle", e.target.value);
              }}
              placeholder="Enter Subtitle"
              error={formErrors.subtitle}
            />
            
          

            <CustomInputTopLabel
              label="Date"
              name="date"
              type="date"
              value={date}
              readOnly={mode === 'view'}
              disabled={mode === 'view'}
              onChange={(e) => {
                setDate(e.target.value);
                validateField("time", e.target.value);
              }}
              placeholder="Select Date of Birth"
              error={formErrors.date}
            />
            <TimeRangePicker
            value={timeRange}
             onChange={(start, end) => {
              setTimeRange(`${start} - ${end}`);
              validateField("timeRange", `${start} - ${end}`);
            }}
            readOnly={mode === 'view'}
            disabled={mode === 'view'}
            error={formErrors.timeRange}

            />
          
            
          
          </div>

        </div>

        <div className="user-popup-button-container">
          <div>
            {mode === 'view' ? '' : <CustomButton label={mode === 'update' ? 'Update Event' : 'Create Event'} onClick={handleSubmit} type="submit" styles={{ minWidth: '170px', marginTop: 10 }} />}
          </div>
          <div>
            <CustomButton label={mode === 'view' ? "Close" : "Cancel"} onClick={handleClose} type="cancel-btn" styles={{ minWidth: '170px', marginTop: 10 }} />
          </div>
        </div>
      </div>)}
    </CustomPopup>
  );

}

export default UserPopUp;
