// src/redux/actions/eventActions.js

import {
    createEventApi,
    getAllEventsApi,
    getEventByIdApi,
    updateEventApi,
    deleteEventApi,
    getFutureEventsApi,
    getPastEventsApi,
  } from '../../utils/eventApi';
  
  // Action Types
  export const CREATE_EVENT = 'CREATE_EVENT';
  export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
  export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID';
  export const UPDATE_EVENT = 'UPDATE_EVENT';
  export const DELETE_EVENT = 'DELETE_EVENT';
  export const GET_FUTURE_EVENTS = 'GET_FUTURE_EVENTS';
  export const GET_PAST_EVENTS = 'GET_PAST_EVENTS';
  
  // Action Creators
  export const createEventSuccess = (event) => ({
    type: CREATE_EVENT,
    payload: event,
  });
  
  export const getAllEventsSuccess = (events) => ({
    type: GET_ALL_EVENTS,
    payload: events,
  });
  
  export const getEventByIdSuccess = (event) => ({
    type: GET_EVENT_BY_ID,
    payload: event,
  });
  
  export const updateEventSuccess = (event) => ({
    type: UPDATE_EVENT,
    payload: event,
  });
  
  export const deleteEventSuccess = (eventId) => ({
    type: DELETE_EVENT,
    payload: eventId,
  });
  
  export const getFutureEventsSuccess = (futureEvents) => ({
    type: GET_FUTURE_EVENTS,
    payload: futureEvents,
  });
  
  export const getPastEventsSuccess = (pastEvents) => ({
    type: GET_PAST_EVENTS,
    payload: pastEvents,
  });
  
  export const clearEvent = () => {
    return {
        type: "CLEAR_EVENT"
    };
};
  // Thunk Actions
  export const createEvent = (eventData) => async (dispatch) => {
    try {
      const event = await createEventApi(eventData);
      dispatch(createEventSuccess(event));
    } catch (error) {
      console.error('Error creating event:', error);
      // Handle error as needed
    }
  };
  
  export const getAllEvents = () => async (dispatch) => {
    try {
      const events = await getAllEventsApi();
      dispatch(getAllEventsSuccess(events));
    } catch (error) {
      console.error('Error fetching events:', error);
      // Handle error as needed
    }
  };
  
  export const getEventById = (eventId) => async (dispatch) => {
    try {
      const event = await getEventByIdApi(eventId);
      dispatch(getEventByIdSuccess(event));
    } catch (error) {
      console.error('Error fetching event by ID:', error);
      // Handle error as needed
    }
  };
  
  export const updateEvent = (eventId, eventData) => async (dispatch) => {
    try {
      const updatedEvent = await updateEventApi(eventId, eventData);
      dispatch(updateEventSuccess(updatedEvent));
    } catch (error) {
      console.error('Error updating event:', error);
      // Handle error as needed
    }
  };
  
  export const deleteEvent = (eventId) => async (dispatch) => {
    try {
      await deleteEventApi(eventId);
      dispatch(deleteEventSuccess(eventId));
    } catch (error) {
      console.error('Error deleting event:', error);
      // Handle error as needed
    }
  };
  
  export const getFutureEvents = () => async (dispatch) => {
    try {
      const futureEvents = await getFutureEventsApi();
      dispatch(getFutureEventsSuccess(futureEvents));
    } catch (error) {
      console.error('Error fetching future events:', error);
      // Handle error as needed
    }
  };
  
  export const getPastEvents = () => async (dispatch) => {
    try {
      const pastEvents = await getPastEventsApi();
      dispatch(getPastEventsSuccess(pastEvents));
    } catch (error) {
      console.error('Error fetching past events:', error);
      // Handle error as needed
    }
  };
  