import React, { useState, useEffect } from 'react';
import './EventManagment.css'
import TitleCard from '../../../components/Dashboard/TitleCard/TitleCard';
import CustomTable from '../../../components/Dashboard/CustomTable/CustomTable';
import IconButton from '../../../components/Dashboard/IconButton/IconButton';
import UserPopUp from './UserPopUp/UserPopUp';
import axios from 'axios';
import { createEventSuccess, updateEventSuccess, deleteEventSuccess, clearEvent } from './../../../redux/actions/eventActions';
import { useDispatch, useSelector } from 'react-redux';
import DeletePopUp from '../../../components/Dashboard/DeletePopUp/DeletePopUp';
import NoData from '../../../components/Dashboard/NoData/NoData';
import CustomButton from '../../../components/CustomButton/CustomButton';

const EventManagmentContainer = () => {
  const [isUserPopUpOpen, setIsUserPopUpOpen] = useState(false);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [mode, setMode] = useState('')
  const [eventType, seteventType] = useState('future')
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const [userId, setUserId] = useState('')
  // const [deleteUser, setdeleteUser] = useState(false)

  const dispatch = useDispatch();
  let usersData = useSelector((state) => state.events);
  let userArray = usersData.events
  console.log(userArray);
  const closeUserPopUp = () => {
    setIsUserPopUpOpen(false);
  }; const openUserPopUp = () => {
    setIsUserPopUpOpen(true);
  };

  const closeDeletePopUp = () => {
    setIsDeletePopUpOpen(false);
  }; const openDeletePopUp = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleButtonClick = (mode, user_id) => {
    console.log('Button clicked with mode:', mode, user_id);
    setMode(mode)
    console.log(user_id);
    user_id && setUserId(user_id);
    // Do other things based on the mode value
    if (user_id) {
      openUserPopUp()
    }
  };
  const handleButtonClickCreate = (mode) => {
    console.log('Button clicked with mode:', mode);
    setMode(mode)
    setUserId('')
    // Do other things based on the mode value

    openUserPopUp()
  };
  const handleDeleteClick = () => {
    console.log(userId);
    if (userId) {
      const apiUrl = `/api/events/${userId}`;

      axios.delete(apiUrl)
        .then((response) => {
          // Handle the success response here
          console.log('User deleted successfully:');
          dispatch(deleteEventSuccess(userId));

          closeDeletePopUp()
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error('Error submitting data:', error);
        });
    }
  }
  const handleDeleteButtonClick = (mode, user_id) => {
    console.log('Button clicked with mode:', mode, user_id);
    setMode(mode)
    console.log(user_id);
    user_id && setUserId(user_id);
    // Do other things based on the mode value
    if (user_id) {
      openDeletePopUp()
    }
  };
  useEffect(() => {

    console.log('lessorArray');
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/events-${eventType}`);
        console.log(`/api/events-${eventType}`);
        const userData = response.data;
        console.log(userData);
        dispatch(clearEvent());
        // setUserData(userData)

        userData.forEach((lessor) => {
          dispatch(createEventSuccess(lessor));
        });
        // console.log(lessorsData[0].totalPages, lessorsData[0].totalUsers);

        // setTotalPage(lessorsData[0].totalPages)
        // setTotalRows(lessorsData[0].totalUsers)
      } catch (error) {
        // setError('Error fetching user data.');
      }
    };
    fetchData();



  }, [eventType]);
  // const headers = ['Photo', 'First name', 'Last name', 'Email', 'Phone No', 'DoB', 'Address', 'Division', 'Role', 'Action'];
  const headers = ['Title', 'Subtitle', 'Date', 'Time', 'Action'];
  const cellWidths = [
    { minWidth: '100px', maxWidth: 'auto', width: '21vw' }, // Address
    { minWidth: '100px', maxWidth: 'auto', width: '21vw' }, // Address
    { minWidth: 'auto', maxWidth: 'auto', width: '21vw' }, // Address
    { minWidth: 'auto', maxWidth: 'auto', width: '21vw' }, // Address
    { minWidth: 'auto', maxWidth: 'auto', width: '5vw' }, // Address

  ];
  const rowAlignments = ['left', 'left', 'center', 'center', 'right']

  const user_data = userArray.map((element, index) => ([
    // <Avatar key={`avatar-${index}`} imageUrl={element.user_image} />,
    element.title,
    element.subtitle,
    element.date.substring(0, 10),
    element.time_range,

    (
      <>
        {eventType == 'future' ? <IconButton key={`pencil-${index}`} onClick={handleButtonClick} buttonIcon={'fa fa-solid fa-pencil'} color={'green'} mode='update' user_id={element._id} /> : ''}
        <IconButton key={`trash-${index}`} onClick={handleDeleteButtonClick} buttonIcon={'fa fa-solid fa-trash'} color={'red'} mode={'delete'} user_id={element._id} />
      </>
    )
  ]));

  const userIds = userArray.map((element) => element._id);
  console.log('user_data', user_data);

  const indexOfLastImage = currentPage * dataPerPage;
  const indexOfFirstImage = indexOfLastImage - dataPerPage;
  const currentData = user_data.slice(indexOfFirstImage, indexOfLastImage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div>
      <UserPopUp isOpenProp={isUserPopUpOpen} closePopup={closeUserPopUp} mode={mode} userId={userId} eventType={eventType} />
      <DeletePopUp isOpenProp={isDeletePopUpOpen} closePopup={closeDeletePopUp} onclick={handleDeleteClick} title={'Delete Event'} description={'Are you sure want to delete event'} deleteButtonTitle={'Delete'} />

      <TitleCard
        title='Event   Managment'
        buttonIcon={'fa fa-solid fa-user-plus'}
        buttonTitle={'Create User'}
        onClick={handleButtonClickCreate}
        mode="create"
      />
      {console.log(eventType)}
      <div className='event-btn-container'>
        <CustomButton label={'Future Events'} onClick={() => seteventType('future')} type="submit" styles={{
          background: eventType === 'past' ? 'rgb(255, 255, 255)' : '#0173A7',
          color: eventType === 'past' ? '#0173A7' : 'rgb(255, 255, 255)'
        }} />
        <CustomButton label={'Past Events'} onClick={() => seteventType('past')} type="submit" styles={{
          background: eventType === 'future' ? 'rgb(255, 255, 255)' : '#0173A7',
          color: eventType === 'future' ? '#0173A7' : 'rgb(255, 255, 255)'
        }} />

      </div>
      {currentData && currentData.length > 0 ? <CustomTable tableHead={headers} tableData={currentData} cellWidths={cellWidths} rowAlignments={rowAlignments} onClick={handleButtonClick} mode="view" userIds={userIds} /> : <NoData />}
      <div className="pagination">
        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        {Array.from({ length: Math.ceil(userArray.length / dataPerPage) }).map((_, index) => (
          <button key={index} onClick={() => paginate(index + 1)} className={currentPage === index + 1 ? 'active' : ''}>
            {index + 1}
          </button>
        ))}
        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === Math.ceil(userArray.length / dataPerPage)}>
          Next
        </button>
      </div>
    </div>
  );
};


export default EventManagmentContainer;