



const navConfig = [
  // {
  //   title: 'Dashboard',
  //   path: '/dashboard/app',
  //   icon: 'fa-solid fa-house',
  // },
  {
    title: 'Event Managment',
    path: '/dashboard/event-managment',
    icon: 'fa-solid fa-calendar-day',
  },
  {
    title: 'Gallery Managment',
    path: '/dashboard/gallery-managment',
    icon: 'fa-solid fa-calendar-day',
  },

];

export default navConfig;
